const MainLoop = require('mainloop.js');

import * as tools from './modules/tools.js'
import Font from './modules/font.js'
import Computer, {Mouse} from './modules/computer.js'
import TV, {VideoCable} from './modules/tv.js'
import {Disk} from './modules/disk.js'

import halProgram from './modules/programs/HAL/HAL.js'
import oceanProgram from './modules/programs/secret ocean/ocean.js'
import pointerFrenzyProgram from './modules/programs/pointer frenzy!/pointer_frenzy.js'

const dataFont = new Font("Press Start 2P");
const tv = new TV(dataFont);
const cptr = new Computer(320, 200, dataFont);

const cable = new VideoCable("VIDEO 1");
cptr.graphics.videoCable = cable;

const setupMouse = (tvOutput) => {
  document.body.appendChild( tvOutput );
  const mouseDiv = document.createElement('div');
  mouseDiv.style['position']="absolute";
  mouseDiv.className = "mouse";
  document.body.appendChild(mouseDiv);
  const resize = () => {
    const padding = tvOutput.width * 0.0822;
    const width = tvOutput.width-padding, height = tvOutput.height;
    mouseDiv.width = width;
    mouseDiv.height = height;

    var offsets = tvOutput.getBoundingClientRect();
    var top = offsets.top;
    var left = offsets.left+(padding/2);

    mouseDiv.style['top'] = top + "px";
    mouseDiv.style['left'] = left + "px";
    mouseDiv.style['width'] = width + "px";
    mouseDiv.style['height'] = height + "px";
  }
  resize();
  window.addEventListener('resize', resize, false);

  const mouse = new Mouse(mouseDiv);
  //TODO: Connect in nicer way
  cptr.mouse = mouse;
  return mouse;
}

const setupMainLoop = () => {
  MainLoop
  .setBegin(() => {
    cptr.begin();
  })
  .setUpdate((delta) => {
    cptr.update(delta);
    tv.update(delta);
  })
  .setDraw((interpolationPercentage) => {
    cptr.draw(interpolationPercentage);
    tv.draw(interpolationPercentage);
  })
  .setEnd(() => {
    cptr.end();
  })
  .start();
  //MainLoop.setMaxAllowedFPS(10);
  //MainLoop.setSimulationTimestep(1000/50);
/*
  setInterval(() => {
    console.info("FPS:", MainLoop.getFPS())
  }, 3000);
*/
}

const disk = new Disk("HAL", {
  "HAL 0.9b": halProgram,
  "SECRET OCEAN": oceanProgram,
  "Pointer Frenzy 0.1a": pointerFrenzyProgram
}, false);

tools.waitForDOM()
.then(() => tv.start())
.then(setupMouse)
.then(setupMainLoop)
.then(tools.sleep(1000 * 1)) //Let's look at some static noise before plugging in the cable..
.then(() => tv.insertVideoCable(cable))
.then(() => cptr.boot(2000 * 1)) //and 1 sec of load screen as well.
.then(() => cptr.insertDisk(disk));
