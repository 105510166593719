import FontFaceObserver from 'fontfaceobserver'
import * as tools from './tools.js'

//TODO: Fix loading of font. Load should return the Font-object somehow.
//TODO: also draw should not specify size etc, maybe load for a specific size/color combo and get a font for that?
export default class Font {
  constructor(name, charCount = 256, url) {
    this._name = name;
    this._charCount = charCount;
    this._cache = {};
    if(url != undefined) {
      var junction_font = new FontFace(name, 'url(' + url + ')');
      this.load = () => junction_font.load().then(function(loaded_face) {
      	document.fonts.add(loaded_face);
      	document.body.style.fontFamily = '"' + name + '", Arial';
        return new FontFaceObserver(name).load();
      });
    } else {
        this.load = () => new FontFaceObserver(name).load();
    }
  }
  
  draw(ctx, text, x, y, size = 16, width = 16, height = 16, color, background = undefined) {
    ctx.save();
    
    const key = size + "|" + height + "|" + color,
          columns = Math.ceil(Math.sqrt(this._charCount)), rows = columns;
    
    var fontMap = this._cache[key];
    const xPadding = 1, yPadding = 3;
    if(fontMap == undefined) {
      const fontCanvas = document.createElement( 'canvas'),
            charCount = this._charCount,
            fontName = this._name;
      fontCanvas.width = (width+xPadding) * columns;
      fontCanvas.height = (height+yPadding) * rows;
      
      //canvas.style="width: 100%; height: 100%;";
      //document.body.appendChild(fontCanvas);

      const fontCtx = fontCanvas.getContext("2d");
      tools.setpixelated(fontCanvas, fontCtx);
      
      fontCtx.fillStyle = color;
      fontCtx.textAlign="start";
      fontCtx.textBaseline = 'top';
      fontCtx.font = size + "px '" + fontName + "'";

      for(var column = 0; column < columns; column++) {
        for(var row = 0; row < rows; row++) {
          fontCtx.fillText(String.fromCharCode(column+row*columns), column*(width+xPadding), row*(height+yPadding));
        }
      }

      fontMap = this._cache[key] = {canvas: fontCanvas, size: size};
    }
    
    const lines = text.split("\n");
    for (var rowOffs = 0; rowOffs < lines.length; rowOffs++) {
      const line = lines[rowOffs];
      for(var i = 0; i < line.length; i++) {
        var char = line.charCodeAt(i);

        const row=Math.floor(char/columns),
            column=char%columns
        
        if(background) {
          ctx.fillStyle = background;
          ctx.fillRect(x+i*width, y, width, height);
        }/* else {
          ctx.clearRect((x+i)*fontSize, y*fontSize, fontSize, fontSize);
        }*/
        ctx.drawImage(
          fontMap.canvas,

          column*(width+xPadding),
          row*(height+yPadding),
          width,
          height,

          x + i*width,
          y + rowOffs*height,
          width,
          height
        )
      }
    }
    ctx.restore();
  }
}
