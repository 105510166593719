import _ from 'lodash'

export class SpriteMap {
  constructor(image, width = 16, height = 16) {
    this.image = image;
    this.width = width;
    this.height = height;
    this.cols = image.width/width;
    this.rows = image.height/height;
  }
  draw(ctx, x = 0, y = 0, idx = 0, w, h) {
    const row=Math.floor(idx/this.cols),
        col=idx%this.cols;
    if(w == undefined && h != undefined) {
      w = this.width * (h/this.height);
    } else if(w != undefined && h == undefined) {
      h = this.height * (w/this.width);
    }
    ctx.drawImage(
      this.image,
      col * this.width,
      row * this.height,
      this.width,
      this.height,
      x,
      y,
      w ? w : this.width,
      h ? h : this.height);
  }
}

export class Animation {
  constructor(spriteMap, fps = 25, frames, loop = true) {
    this.spriteMap = spriteMap;
    this.fps = fps;
    this.loop = loop;
    const frameCount = spriteMap.rows * spriteMap.cols;
    if(frames == undefined) {
      frames = _.range(frameCount);
    }
    this.frames = frames;
    this.reset();
    this.play();
  }
  play() {
    this.fpsMultiplier = 1;
  }
  pause() {
    this.fpsMultiplier = 0;
  }
  reverse() {
    this.fpsMultiplier = -1;
  }
  reset() {
    this.frameIdx = 0;
  }
  update(delta) {
    this.frameIdx += delta * ((this.fps*this.fpsMultiplier)/1000);
    if(!this.loop) {
      if(this.frameIdx > this.frames.length-1) {
          this.frameIdx = this.frames.length-1;
      } else if(this.frameIdx < 0) {
          this.frameIdx = 0;
      }
    }
  }
  draw(ctx, x, y, w, h) {
    const frame = this.frames[Math.floor(this.frameIdx)%this.frames.length];
    if(frame != -1) {
      this.spriteMap.draw(ctx, x, y, frame, w, h)
    }
  }
}
