import Program from '../../program.js'
import halMusicFile from 'url:./HAL.mp3';

const hal0_9b = new Program((computer) => {
  const startInfiniteBuzz = () => {
    computer.graphics.videoCable.features.buzz().then(startInfiniteBuzz);
  }

  const musicPromise = new Promise((resolve, reject) => {
    const music = new Howl({
      src: [halMusicFile],
      preload: true,
      autoplay: false,
      onplayerror: function() {
        music.once('unlock', function() {
            music.play("lobby");
        });
      },
      sprite: {
        lobby: [0, 8000, true]
      },
      onload: () => resolve(music)
    });
  });

  function createLinkAction(url) {
      const URLLoadingProgram = new Program((computer) => {
        return new Promise((resolve) => {
          window.location = url;
          //never resolve as we're leaving the site
        })
      });

      return () => {
        computer.PROGRAM_LOADER.load(URLLoadingProgram);
        return true;
      }
  };
  function createTheme(name, backgroundColor, textColor) {
    return {
      name: name,
      colors: {background: backgroundColor, text: textColor },
    };
  }

  const themes = [
    createTheme("DEFAULT", 14, 1),
    createTheme("MONO", 0, 5)
  ],
  logo =
  " _____ _____ __    \n" +
  "|  |  |  _  |  |   \n" +
  "|     |     |  |__ \n" +
  "|__|__|__|__|_____|";

  var themeIdx = parseInt(window.localStorage.getItem("selectedTheme") || 0);
  var theme = themes[themeIdx%themes.length];
  return Promise.all([musicPromise])
  .then((resources) => {
    startInfiniteBuzz();
    const music = resources[0],
          cursor = computer.resources.cursorImg,
          ClickableText = computer.API.ClickableText;

    const menuItems = [
      new ClickableText("ATOMDELTA  ", 0, 6, createLinkAction("http://atomdelta.se")),
      new ClickableText("DARKBITS   ", 0, 7, createLinkAction("http://darkbits.org")),
      new ClickableText("MOOBELDAJA ", 0, 8, createLinkAction("http://moobeldaja.ee")),
      new ClickableText("MAHRI MAHRI", 0, 9, createLinkAction("http://mahrimahri.com"))
    ];

    const volumeUp = new ClickableText("[+]", 9, 11, () => computer.API.volumeUp());
    const volumeDown = new ClickableText("[-]", 6, 11, () => computer.API.volumeDown());

    const themeItems = themes.map((currTheme, idx) => {
      return new ClickableText(() => { return theme == currTheme ? "[X]" : "[ ]"; }, 14, 12 + idx, () => {
        if(theme != currTheme) {
          theme = currTheme;
          window.localStorage.setItem("selectedTheme", themes.indexOf(theme));
          printScreen();
          return true;
        }
      })
    })
    const allClickableTexts = menuItems.concat(themeItems).concat([volumeUp, volumeDown]);

    function printScreen() {
      const textColor = theme.colors.text, backgroundColor = theme.colors.background;
      computer.textScreen.clear(backgroundColor);
      computer.textScreen.print(logo, 0, 0, textColor, backgroundColor);

      computer.textScreen.print("SOUND", 0, 11, textColor, backgroundColor);
      computer.textScreen.print("THEME", 0, 12, textColor, backgroundColor);
      for(var i = 0; i < themes.length; i++) {
        computer.textScreen.print(themes[i].name, 6, 12+i, textColor, backgroundColor);
      }
      allClickableTexts.forEach((clickableText) => {
        clickableText.print(textColor, backgroundColor);
      })
    }

    computer.palette = computer.defaultPalette;
    printScreen();
    music.play("lobby");
    var tid = 0;
    return {
        draw: (interpolationPercentage) => {
          const ctx = computer.graphics.ctx;
          computer.textScreen.draw(ctx, computer.systemFont, computer.palette);
          ctx.drawImage(cursor,
            computer.mouse.pos.x,
            computer.mouse.pos.y
          );
        },
        begin: () => {
          const textColor = theme.colors.text, backgroundColor = theme.colors.background;
          allClickableTexts.forEach((clickableText) => {
            clickableText.processInput(textColor, backgroundColor);
          })
        }
    };
  });
});

export default hal0_9b;
