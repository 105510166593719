import * as tools from './tools.js'
const ko = require('ko-observable');

import {PerspectiveCamera, Scene, Texture, LinearFilter, MeshBasicMaterial, PlaneGeometry, Mesh, WebGLRenderer} from 'three'
const THREE = {PerspectiveCamera, Scene, Texture, LinearFilter, MeshBasicMaterial, PlaneGeometry, Mesh, WebGLRenderer};

import RenderPass from './bad-tv/postprocessing/RenderPass.js'
import ShaderPass from './bad-tv/postprocessing/ShaderPass.js'
import EffectComposer from './bad-tv/postprocessing/EffectComposer.js'
import FilmShader from './bad-tv/shaders/FilmShader.js'
import StaticShader from './bad-tv/shaders/StaticShader.js'
import BadTVShader from './bad-tv/shaders/BadTVShader.js'
import RGBShiftShader from './bad-tv/shaders/RGBShiftShader.js'
import CopyShader from './bad-tv/shaders/CopyShader.js'

class OSD {
  constructor(tv, font) {
    this.font = font;
    this.tv = tv;
    this.timeSinceVolume = 0;
    tv.volume.subscribe((volume) => {
      this.timeSinceVolume = Date.now();
    });
    
    this.timeSinceChannel = 0
    tv.selectedChannelName.subscribe((name) => {
      this.timeSinceChannel = Date.now();
    });
  }
  draw(ctx) {
    ctx.save();
    if(Date.now() < this.timeSinceChannel + 6000) {
      this.font.draw(ctx, this.tv.selectedChannelName(), 16, 16, 16, 16, 16, "#00FF00");
    }
    if(this.timeSinceVolume + 3000 > Date.now()) {
      const volumeStr = "|".repeat(this.tv.volume()) + "·".repeat(this.tv.maxVolume-this.tv.volume());
      this.font.draw(ctx, "VOLUME\n" + volumeStr, 16, 10*16, 16, 16, 16, "#00FF00");
    }
    ctx.restore();
  }
}

export class VideoCable {
  constructor(name) {
    this.name = name;
    this.features = {};
    this._source = ko.observable();
  }
  get source() {
    return this._source();
  }
  set source(canvas) {
    if(canvas == undefined) {
      this._source(undefined);
    } else {
      this._source({canvas: canvas, ctx: canvas.getContext("2d")});
    }
  }
}

export default class TV {
  constructor(OSDFont, volume = 5, maxVolume = 18) {
    this.volume = ko.observable(window.localStorage.getItem("tvVolume"));
    if(this.volume() == undefined) {
      this.volume(volume);
    } else {
      this.volume(parseInt(this.volume()));
    }
    this.maxVolume = maxVolume;
    this.videoCables = ko.observable({});
    this.selectedChannelName = ko.observable();        
    this._videoCable = ko.computed(() => {
      return this.videoCables()[this.selectedChannelName()];
    });
    
    ko.computed(() => {
      return {name: this.selectedChannelName(), cable: this._videoCable()};
    }).subscribe((ted) => {
        if(ted.cable == undefined) {
          this.breakTv(0.3); //random static when changing to unplugged cable
        } else {
          this.buzz(); //Light buzz when change of channel.
        }
    })
    
    this._videoCable.subscribe((videoCable) => {
      if(videoCable != undefined) {
        videoCable.features.volumeUp = this.volumeUp.bind(this);
        videoCable.features.volumeDown = this.volumeDown.bind(this);
        videoCable.features.buzz = this.buzz.bind(this);
      }
    })
    this.OSD = new OSD(this, OSDFont);
    
    this.source = ko.computed(() => {
      var source = this._videoCable() ? this._videoCable().source : this.noSignalSource;
      if(source == undefined) {
        const noSignalCanvas = document.createElement('canvas');
        noSignalCanvas.width = 320;
        noSignalCanvas.height = 200;
        noSignalCanvas.id = "NO_SIGNAL_CANVAS";
        const noSignalCtx = noSignalCanvas.getContext("2d");
        noSignalCtx.fillStyle = "#000000";
        noSignalCtx.fillRect(0, 0, noSignalCanvas.width, noSignalCanvas.height);
        this.noSignalSource = {canvas: noSignalCanvas, ctx: noSignalCtx};
        source = this.noSignalSource;
      }
      return source;
    });

    const tvXres = 1080;
    const tvYres = 720;
    
    const camera = new THREE.PerspectiveCamera(55, tvXres/tvYres, 20, 3000);
    camera.position.z = 1000;
    const scene = new THREE.Scene();
    
    this.videoCanvas = document.createElement('canvas');
    this.videoCanvas.id = "VIDEO_CANVAS";
    const sourceCanvas = this.source().canvas;

    this.videoCanvas.width = sourceCanvas.width;
    this.videoCanvas.height = sourceCanvas.height;
    
    //init video texture
    this.videoTexture = new THREE.Texture( this.videoCanvas );
    this.source.subscribe((source) => {
      this.videoCanvas.width = source.canvas.width;
      this.videoCanvas.height = source.canvas.height;
    });

    this.videoTexture.minFilter = THREE.LinearFilter;
    this.videoTexture.magFilter = THREE.LinearFilter;
    const videoMaterial = new THREE.MeshBasicMaterial( {
      map: this.videoTexture
    } );
    //Add video plane
    var planeGeometry = new THREE.PlaneGeometry(tvXres, tvYres, 1,1);
    var plane = new THREE.Mesh( planeGeometry, videoMaterial );
    scene.add( plane );
    plane.z = 0;
    plane.scale.x = plane.scale.y = 1.45;

    //init renderer
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize( 640, 480 );
    
    //POST PROCESSING
    //Create Shader Passes
    const renderPass = new RenderPass( scene, camera );
    this.badTVPass = new ShaderPass( BadTVShader );

    const rgbPass = new ShaderPass( RGBShiftShader );
    this.filmPass = new ShaderPass( FilmShader );

    this.staticPass = new ShaderPass( StaticShader );

    const copyPass = new ShaderPass( CopyShader );
    //set shader uniforms
    this.filmPass.uniforms.grayscale.value = 0;
    
    this.staticPass.uniforms[ 'amount' ].value = 0.50;
    this.staticPass.uniforms[ 'size' ].value = 2;
    this.fixTv = () => {
      this.staticPass.uniforms[ 'amount' ].value = 0.00;  
      this.staticPass.uniforms[ 'size' ].value = 1.0;
      this.badTVPass.uniforms[ 'distortion' ].value = 0.1;
      this.badTVPass.uniforms[ 'distortion2' ].value = 0.1;
      this.badTVPass.uniforms[ 'speed' ].value = 0;
      this.badTVPass.uniforms[ 'rollSpeed' ].value = 0;
      rgbPass.uniforms[ 'angle' ].value = 0;
      rgbPass.uniforms[ 'amount' ].value = 0.00;
      this.filmPass.uniforms[ 'sCount' ].value = 800;
      this.filmPass.uniforms[ 'sIntensity' ].value = 0.9;
      this.filmPass.uniforms[ 'nIntensity' ].value = 0.4;
    };
    this.breakTv = (st) => {
      this.filmPass.uniforms[ 'sCount' ].value = 800;
      this.filmPass.uniforms[ 'sIntensity' ].value = 0.9;
      this.filmPass.uniforms[ 'nIntensity' ].value = 0.4;
      this.badTVPass.uniforms[ 'distortion' ].value = Math.random()*3+0.1;
      this.badTVPass.uniforms[ 'distortion2' ].value =Math.random()*3+0.1;
      if(Math.random() > .8) {
        this.badTVPass.uniforms[ 'speed' ].value = 0.04 + Math.random()*0.4;
        this.badTVPass.uniforms[ 'rollSpeed' ].value = 0.04 + Math.random()*0.1;
      }
      rgbPass.uniforms[ 'angle' ].value = Math.random()*2;
      rgbPass.uniforms[ 'amount' ].value = Math.random()*0.03;
      st = st || 0.1;
      this.staticPass.uniforms[ 'size' ].value = 2 + Math.random()*st * 3;
      this.staticPass.uniforms[ 'amount' ].value = st + Math.random()*st;
    }
    
    //Add Shader Passes to Composer
    //order is important 
    this.composer = new EffectComposer( renderer );
    this.composer.addPass( renderPass );
    this.composer.addPass( this.filmPass );
    this.composer.addPass( this.badTVPass );
    this.composer.addPass( rgbPass );
    this.composer.addPass( this.staticPass );
    this.composer.addPass( copyPass );
    copyPass.renderToScreen = true;

    function onResize() {
      const paddingForGlitch = 100;
      const tvAspect = (tvXres+paddingForGlitch)/tvYres;
      var width = window.innerWidth;
      var height = window.innerHeight;
      const windowAspect = width / height;
      if(windowAspect < tvAspect) {
        height = width/tvAspect;
      } else {
        width = height*tvAspect;
      }
      
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    }

    window.addEventListener('resize', onResize, false);
    onResize();
    
    this.buzz = (st) => {
      return Promise.resolve(this.breakTv(st))
      .then(tools.sleep(Math.random()*700))
      .then(this.fixTv)
      .then(tools.sleep(Math.random()*10000))
    }
    this.tvOutput = renderer.domElement;
  }
  insertVideoCable(videoCable) {
    this.videoCables()[videoCable.name] = videoCable;
    this.videoCables(this.videoCables());
  }
  setChannel(name) {
    this.selectedChannelName(name);
  }
  start() {
    console.info("TURNING ON TV");
    return this.OSD.font.load().then(() => {
      Howler.mute(false);
      Howler.volume(this.volume()/this.maxVolume);
      this.selectedChannelName("VIDEO 1");
      return this.tvOutput;      
    });
  }
  update(delta) {
    const shaderTimeDelta = delta*0.006;
    this.badTVPass.uniforms[ 'time' ].value +=  shaderTimeDelta;
    this.filmPass.uniforms[ 'time' ].value +=  shaderTimeDelta;
    this.staticPass.uniforms[ 'time' ].value += shaderTimeDelta;
  }
  draw() {
    const videoCtx = this.videoCanvas.getContext("2d");
    videoCtx.clearRect(0, 0, this.videoCanvas.width, this.videoCanvas.height);
    videoCtx.drawImage(this.source().canvas, 0, 0);
    this.OSD.draw(videoCtx);
    this.videoTexture.needsUpdate = true;
    this.composer.render();
  }
  setVolume(volume) {
    if(volume > this.maxVolume || volume < 0) return false;
    this.volume(volume);
    Howler.volume(this.volume()/this.maxVolume);
    window.localStorage.setItem("tvVolume", this.volume());
    return true;
  }
  volumeUp() {
    return this.setVolume(this.volume()+1)
  }
  volumeDown() {
    return this.setVolume(this.volume()-1)
  }
}
