export function isFunction(functionToCheck) {
 return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}
export function setpixelated(canvas, context){
    context['imageSmoothingEnabled'] = false;       /* standard */
    context['webkitImageSmoothingEnabled'] = false; /* Safari */
    context['mozImageSmoothingEnabled'] = false;    /* Firefox */
    context['oImageSmoothingEnabled'] = false;      /* Opera */
    context['msImageSmoothingEnabled'] = false;     /* IE */
    canvas.style["image-rendering"] = "pixelated";
}
export function waitForDOM() {
    return new Promise((resolve) => {
      console.info("WAITING FOR DOM")
      // in case the document is already rendered
      if (document.readyState!='loading') resolve();
      // modern browsers
      else if (document.addEventListener) document.addEventListener('DOMContentLoaded', resolve);
      // IE <= 8
      else document.attachEvent('onreadystatechange', () => {
          if (document.readyState=='complete') resolve();
      });      
    });
}
export function loadImage(src) {
    return new Promise((resolve, reject)=> {
      const img = new Image();
      img.onload = ()=> resolve(img);
      img.src = src;
      return img;
    });
}
export function sleep(ms) {
  return (x) => new Promise(resolve => setTimeout(() => resolve(x), ms));
}
export function toScreenMemValue(char, color, background) {
  return (char << 8) | (background << 4) | (color & 0x000F);
}
export function fromScreenMemValue(value) {
    return {
      char: (value>>8) & 0x00FF,
      background: (value>>4) & 0x000F,
      color: value & 0x000F
    }
}
