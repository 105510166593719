import {Howl} from 'howler'
import * as tools from './tools.js'
import Program from './program.js'

import blipSoundFile from 'url:../assets/sound/blip.wav';
import blopFile from 'url:../assets/sound/blop.wav'
import negativeSoundFile from 'url:../assets/sound/negative.wav';

import cursorFile from 'url:../assets/image/cursor.png'
const ko = require('ko-observable');

const blipPromise = new Promise((resolve, reject) => {
  const blip = new Howl({
    src: [blipSoundFile],
    preload: true,
    onload: () => resolve(blip)
  });
});

const blopPromise = new Promise((resolve, reject) => {
  const blop = new Howl({
    src: [blopFile],
    preload: true,
    autoplay: false,
    onload: () => {
      var unlockAudio = () => {
        Howler.mute(true);
        blop.play();
        blop.stop();
        Howler.mute(false);
        window.removeEventListener("click", unlockAudio);
      };
      window.addEventListener("click", unlockAudio);
      resolve(blop);
    }
  });
});

const negativePromise = new Promise((resolve, reject) => {
  const negative = new Howl({
    src: [negativeSoundFile],
    preload: true,
    autoplay: false,
    onload: () => resolve(negative)
  });
});

export class Mouse {
  constructor(sourceElement) {
    const self = this;
    this.pos = {x: -100, y: -100};
    this.clicked = false;
    this.btn1Down = false;
    this.maxX = 1;
    this.maxY = 1;
    this._internalState = {clicked: false, x: -100, y: -100, btn1Down: false};

    sourceElement.addEventListener('mousemove', (ev) => {
      var width = sourceElement == window ? window.innerWidth : sourceElement.width;
      var height = sourceElement == window ? window.innerHeight : sourceElement.height;

      const mouseX = ev.pageX - sourceElement.offsetLeft;
      const mouseY = ev.pageY - sourceElement.offsetTop;

      self._internalState.x = Math.ceil((mouseX/width)*this.maxX);
      self._internalState.y = Math.ceil((mouseY/height)*this.maxY);
    });
    (sourceElement == window ? window.document : sourceElement).addEventListener('mouseleave', () => {
      self._internalState.x = -100;
      self._internalState.y = -100;
    });
    var click = 0, down = 0, up = 0;
    sourceElement.addEventListener('click', () => {
      this._internalState.clicked = true;
    })
    sourceElement.addEventListener('mousedown', (ev) => {
      if(ev.button == 0)
        this._internalState.btn1Down = true;
    })
    sourceElement.addEventListener('mouseup', (ev) => {
      if(ev.button == 0)
        this._internalState.btn1Down = false;
    })
  }
  begin() {
    this.pos.x = this._internalState.x;
    this.pos.y = this._internalState.y;
    this.clicked = this._internalState.clicked;
    this.btn1Down = this._internalState.btn1Down;
  }
  end() {
    this._internalState.clicked = this.clicked = false;
  }
}

class TextScreen {
  constructor(cols = 40, rows = 25, fontSize = 8) {
    this.screenBuffer = new Uint16Array(cols*rows);
    this.cols = cols;
    this.rows = rows;
    this.fontSize = fontSize;

    this.canvas = document.createElement('canvas');
    this.canvas.id = "TEXT_SCREEN_CANVAS";
    this.canvas.width = cols * fontSize;
    this.canvas.height = rows * fontSize;
    this.ctx = this.canvas.getContext("2d");
  }
  clear(backgroundColor) {
    const spaceChar = " ".charCodeAt(0);
    for(var i = 0; i < this.screenBuffer.length; i++) {
      const background = backgroundColor == undefined ? tools.fromScreenMemValue(this.screenBuffer[i]).background : backgroundColor;
      this.screenBuffer[i] = tools.toScreenMemValue(spaceChar, 0, background);
    }
  }
  print(text = "", col = 0, row = 0, color = "#FFFFFF", background = "#000000") {
    text = tools.isFunction(text) ? text() : text;
    const lines = text.split("\n");
    for (var y = 0; y < lines.length; y++) {
      const line = lines[y];
      for(var x = 0; x < line.length; x++) {
        var absPos = (x+col)+(y+row)*this.cols;
        this.screenBuffer[absPos] = tools.toScreenMemValue(line.charCodeAt(x), color == undefined ? 0 : color, background == undefined ? 0 : background);
      }
    }
  }
  draw(ctx, font, palette) {
    this.oldScreenBuffer = this.oldScreenBuffer || new Uint16Array(this.screenBuffer.length);
    for(var y = 0; y < this.rows; y++) {
      for(var x = 0; x < this.cols; x++) {
        const i = x + y * this.cols;
        if(this.oldScreenBuffer[i] != this.screenBuffer[i]) {
          const data = tools.fromScreenMemValue(this.screenBuffer[i]);
          font.draw(this.ctx, String.fromCharCode(data.char), x*this.fontSize, y*this.fontSize, this.fontSize, this.fontSize, this.fontSize, palette[data.color], palette[data.background]);
          this.oldScreenBuffer[i] = this.screenBuffer[i];
        }
      }
    }
    ctx.drawImage(this.canvas, 0, 0);
  }
}

class DiskDrive {
  constructor() {
    this.state = ko.observable("EMPTY");
  }
  load(disk) {
    this.state("READING");
    return Promise.resolve(disk)
    .then((disk) => {
      this.disk = disk;
      this.state("READY");
    });
  }
}

class ProgramLoader {
  constructor(computer) {
    this.state = "EMPTY"
    this.computer = computer;
  }
  load(program) {
    this.state = "LOADING";
    //TODO: Stop all sound and clean up etc.
    //TODO: wait for previous disk to finish loading before starting this one.
    return Promise.resolve(program)
    .then((program) => program.load(this.computer))
    .then((runningProgram) => {
      this.program = program;
      this.runningProgram = runningProgram;
      this.state = "RUNNING";
    });
  }
}

class Graphics {
    constructor(screenW, screenH) {
      const canvas = document.createElement( 'canvas');
      canvas.id = "COMPUTER_CANVAS";
      canvas.width = screenW;
      canvas.height = screenH;
      this.state = "OFF";
      this.canvas = canvas;
      //document.body.appendChild(canvas);
      this.ctx = canvas.getContext("2d");

      tools.setpixelated(this.canvas, this.ctx);
    }

    get videoCable() {
      return this._videoCable || {features: {}};
    }
    set videoCable(videoCable) {
      if(this.state == "ON") {
        videoCable.source = this.canvas;
      }
      this._videoCable = videoCable;
    }
    start() {
      this.state = "ON";
      this.videoCable.source = this.canvas;
    }
}

export default class Computer {
  constructor(screenW, screenH, systemFont) {
    this.state = "OFF";
    this.systemFont = systemFont;
    this.textScreen = new TextScreen(40, 25, 8);
    this.defaultPalette = ["#101410", "#B7AAFF", "#68372B", "#70A4B2", "#6F3D86", "#449530", "#352879", "#B8C76F", "#6F4F25", "#433900", "#9A6759", "#444444", "#6C6C6C", "#9AD284", "#4E44D8", "#959595"];
    this.palette = this.defaultPalette;
    this.resources = {};

    this.graphics = new Graphics(screenW, screenH);
    this.API = {
      volumeUp: () => {
        const videoCableFeatures = this.graphics.videoCable.features;
        return videoCableFeatures.volumeUp && videoCableFeatures.volumeUp();
      },
      volumeDown: () => {
        const videoCableFeatures = this.graphics.videoCable.features;
        return videoCableFeatures.volumeDown && videoCableFeatures.volumeDown();
      }
    };

    this.diskDrive = new DiskDrive();
    this.PROGRAM_LOADER = new ProgramLoader(this);
    this.DISK_LOADER = new Program((computer) => {
      const bg = 0, txt = 5;
      computer.textScreen.clear(bg);
      var x = 4, y = 10;
      computer.textScreen.print("LOADER 0.3", x, y, txt, bg);
      computer.textScreen.print("DISK:", x, y+2, txt, bg);
      computer.textScreen.print("*NO DISK*", x+6, y+2, 11, bg);

      var message = "INSERT DISK";
      var menuItems = [];
      computer.diskDrive.state.subscribe((state) => {
        if(state=="EMPTY") {
          computer.textScreen.print("*NO DISK*", x+6, y+2, 11, bg);
        } else if(state=="READING") {
          computer.textScreen.print("*LOADING*", x+6, y+2, txt, bg);
          message = "LOADING DISK...";
        } else if(state=="READY") {
          computer.textScreen.print("         ", x+6, y+2, txt, 0);
          computer.textScreen.print("LOADED (" + computer.diskDrive.disk.name + ")", x+6, y+2, txt, 0);
          const programNames = Object.keys(computer.diskDrive.disk.programs);
          if(computer.diskDrive.disk.autoRun && programNames.length > 0) {
            computer.PROGRAM_LOADER.load(computer.diskDrive.disk.programs[programNames[0]]);
          }

          menuItems = programNames.map((programName, i) => {
            return new computer.API.ClickableText(() => {
              return "[" + programName + "]";
            }, x+9, y+5+i, () => {
              computer.PROGRAM_LOADER.load(computer.diskDrive.disk.programs[programName]);
            });
          });
          menuItems.forEach((menuItem, i) => {
            computer.textScreen.print("SLOT #" + i + ":", x, y+5+i, txt, bg);
            menuItem.print(txt, bg);
          });

          message = "SELECT A PROGRAM";
        }
      });

      return {
        draw: () => {
          const ctx = computer.graphics.ctx;
          computer.textScreen.draw(ctx, computer.systemFont, computer.palette);
          ctx.drawImage(computer.resources.cursorImg,
            computer.mouse.pos.x,
            computer.mouse.pos.y
          );
        },
        update: () => {
          computer.textScreen.print(Date.now() % 2000 < 1000 ? message : "                     ", x, y+4, txt, bg);
        },
        begin: () => {
          menuItems.forEach((menuItem) => {
            menuItem.processInput(txt, bg);
          });
        }
      };
    });

    var computer = this;
    this.API.ClickableText = class ClickableText {
      constructor(name, col, row, action, onMouseEnter = () => computer.resources.blipSound.play(), onMouseLeave = () => {}, onActionSuccess = () => computer.resources.blopSound.play(), onActionFail = () => computer.resources.negativeSound.play()) {
        this.name = name;
        this.col = col;
        this.row = row;
        this.action = action;
        this.focused = false;
        this.onMouseEnter = onMouseEnter;
        this.onMouseLeave = onMouseLeave;
        this.onActionSuccess = onActionSuccess;
        this.onActionFail = onActionFail;
      }
      processInput(textColor, backgroundColor) {
        const text = tools.isFunction(this.name) ? this.name() : this.name,
              x = this.col, y = this.row,
              width = text.length, height = 1,
              fontSize = computer.textScreen.fontSize;

        if(computer.mouse.pos.x >= x*fontSize && computer.mouse.pos.x < (x + width)*fontSize && computer.mouse.pos.y >= y*fontSize && computer.mouse.pos.y < (y + height)*fontSize) {
          if(!this.focused) {
            this.onMouseEnter();
            this.focused = true;
            this.print(textColor, backgroundColor);
          }

          if(computer.mouse.clicked) {
            const actionResponse = this.action();
            if(actionResponse === true) {
              this.onActionSuccess();
            } else if(actionResponse === false) {
              this.onActionFail();
            }
          }
        } else if(this.focused) {
          this.focused = false;
          this.onMouseLeave();
          this.print(textColor, backgroundColor);
        }
      }
      print(textColor, backgroundColor) {
        if(this.focused) {
          computer.textScreen.print(this.name, this.col, this.row, backgroundColor, textColor);
        } else {
          computer.textScreen.print(this.name, this.col, this.row, textColor, backgroundColor);
        }
      }
    }
  }
  set mouse(mouse) {
    this._mouse = mouse;
    const canvas = this.graphics.canvas;
    mouse.maxX = canvas.width;
    mouse.maxY = canvas.height;
  }
  get mouse() {
    return this._mouse;
  }
  boot(loadScreenTime = 0) {
    console.info("BOOTING COMPUTER");

    function glitchTextScreen(textScreen, prob) {
      const screenBuffer = textScreen.screenBuffer;
      var glitchValue = Math.random()*0xFFFF;
      for(var i = 0; i < screenBuffer.length; i++) {
        if(Math.random() > prob) {
          glitchValue = Math.random()*0xFFFF;
        }
        screenBuffer[i] = glitchValue;
      }
    }

    this.graphics.start();
    const canvas = this.graphics.canvas;
    const ctx = this.graphics.ctx;
    ctx.fillStyle = "#444444";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    return this.systemFont.load()
    .then(() => {
      glitchTextScreen(this.textScreen, 0.98);
      this.state = "BOOTING";
      const charFlickerIdx = Math.ceil(Math.random()*(40*25)),
            charFlicker = setInterval(() => {
        if(Math.random() > 0.7) {
          this.textScreen.screenBuffer[charFlickerIdx] ^= (Math.random()*0xFFFF)&0xFF00;
        }
      }, 30);

      return charFlicker;
    })
    .then((charFlicker) => {
      return Promise.all([blipPromise, blopPromise, negativePromise, tools.loadImage(cursorFile), charFlicker])
    })
    .then((resources) => {
      this.resources.blipSound = resources[0];
      this.resources.blopSound = resources[1];
      this.resources.negativeSound = resources[2];
      this.resources.cursorImg = resources[3];
      return resources[4];
    })
    .then(tools.sleep(loadScreenTime))
    .then((charFlicker) => {
      clearTimeout(charFlicker);
      //this.palette = this.defaultPalette;
      //glitchTextScreen(this.textScreen, 0.97);
    })
    //.then(tools.sleep(400))
    .then(()=> {
      console.info("COMPUTER RUNNING");
      this.PROGRAM_LOADER.load(this.DISK_LOADER);
      this.state = "STARTED";
    });
  };

  insertDisk(disk) {
    this.diskDrive.load(disk);
  }
  update(delta) {
    if(this.state == "STARTED" && this.PROGRAM_LOADER.state == "RUNNING" && this.PROGRAM_LOADER.runningProgram.update) {
      this.PROGRAM_LOADER.runningProgram.update(delta);
    }
    if(this.mouse)
      this.mouse.clicked = false;
  }
  begin() {
    this.mouse.begin();
    if(this.state == "STARTED" && this.PROGRAM_LOADER.state == "RUNNING" && this.PROGRAM_LOADER.runningProgram.begin) {
      this.PROGRAM_LOADER.runningProgram.begin();
    }
  }
  end() {
    if(this.state == "STARTED" && this.PROGRAM_LOADER.state == "RUNNING" && this.PROGRAM_LOADER.runningProgram.end) {
      this.PROGRAM_LOADER.runningProgram.end();
    }
    this.mouse.end();
  }
  draw(interpolationPercentage) {
    const ctx = this.graphics.ctx,
          canvas = this.graphics.canvas;
    if(this.state == "OFF") {
      return;
    } else if(this.state == "BOOTING") {
      this.textScreen.draw(ctx, this.systemFont, this.palette);
    } else if(this.state == "STARTED") {
      if(this.PROGRAM_LOADER.state == "RUNNING" && this.PROGRAM_LOADER.runningProgram.draw) {
        this.PROGRAM_LOADER.runningProgram.draw(interpolationPercentage);
      } else if(this.PROGRAM_LOADER.state == "LOADING") {
        const sliderVal = 16;
        var line = 0;
        for (var progress = 0; progress < canvas.height; progress += line) {
          ctx.fillStyle = this.defaultPalette[Math.ceil(Math.random() * 16)];
          ctx.fillRect(0, progress, canvas.width, line);

          line = Math.ceil(Math.random() * sliderVal);
        }
      }
    }
  }
}
